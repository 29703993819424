import React from "react"
import style from "./item-list.mod.scss"
import Pill from "../pill/pill"
import Container from "../container/container"
import Navigator from "../global/navigator"

const ItemList = ({ items, collectionType }) => {
  return (
    <Container gutters width={"medium"} pt={2}>
      <div id={`collection-type__${collectionType}`} className={style.list}>
        <div className={style.list__wrap}>
          {items.map((item) => (
            <Navigator recordId={item.id}>
              <Pill text={item.title} type={"wine"} color={item.color} icon={item.icon?.url}/>
            </Navigator>
          ))}
        </div>
      </div>
    </Container>
  )
}

export default ItemList
