import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/global/layout"
import Intro from "../components/collections/intro"
import FilterLayout from "../components/filter/filter-layout"
import ItemList from "../components/collections/item-list"
import GrapeList from "../components/collections/grape-list"

const CollectionParent = ({
  data: {
    allPortalWine,
    allPortalGrape,
    allPortalTag,
    allPortalPairing,
    wineChildren,
    pairingChildren,
    grapeChildren,
    datoCmsCollectionParent,
    regionChildren,
    regionParents,
    datoCmsCellar
  },
  pageContext: { productContext },
}) => {
  const productData = {
    allWines: allPortalWine.nodes,
    allGrapeTypes: allPortalGrape.nodes,
    allWineTags: allPortalTag.nodes,
    allPairingTypes: allPortalPairing.nodes,
    allWineTypes: productContext.allWineTypes,
  }

  const { title, description, heroImage, seoMetaTags, id, collectionType, featuredWines } =
    datoCmsCollectionParent

  const breadcrumbs = [{ title: title, id: id }]

  return (
    <Layout seo={seoMetaTags}>
      <div>
        <Intro
          title={title}
          description={description}
          image={heroImage}
          breadcrumbs={breadcrumbs}
          items={featuredWines}
          light={(collectionType === "grape" || featuredWines.length === 2) ? true : false}
        />
        {collectionType === "wine" && (
          <ItemList
            collectionType={collectionType}
            items={wineChildren.nodes}
          />
        )}
        {collectionType === "pairing" && (
          <ItemList
            collectionType={collectionType}
            items={pairingChildren.nodes}
          />
        )}
        {collectionType === "grape" && (
          <GrapeList items={grapeChildren.nodes} />
        )}
        <FilterLayout
          productContext={productData}
          regionChildren={regionChildren.nodes}
          regionParents={regionParents.nodes}
          stickerText={datoCmsCellar.stickerText}
        />
      </div>
    </Layout>
  )
}

export default CollectionParent

export const query = graphql`
  query CollectionParentQuery($id: String!) {
    datoCmsCollectionParent(originalId: { eq: $id }) {
      title
      slug
      collectionType
      id: originalId
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      heroImage {
        gatsbyImageData(
          layout: FULL_WIDTH
          imgixParams: { h: "400", fit: "crop", w: "960", q: 60 }
        )
        alt
      }
      description
      featuredWines {
        portalId
        title
        subHeading
        text
        imageOverride {
          gatsbyImageData(
            layout: CONSTRAINED
            imgixParams: { h: "460", fit: "crop", w: "460", q: 60 }
          )
          alt
        }
      }
    }
    wineChildren: allDatoCmsWine {
      nodes {
        id: originalId
        title
        color
      }
    }
    pairingChildren: allDatoCmsPairing {
      nodes {
        id: originalId
        title
        icon {
          url
        }
      }
    }
    grapeChildren: allDatoCmsGrape {
      nodes {
        id: originalId
        title
      }
    }
    regionChildren: allDatoCmsRegionChild {
      nodes {
        id: portalRegionId
        title
        parentRegion {
          id: originalId
        }
      }
    }
    regionParents: allDatoCmsRegionParent(sort: { fields: position }) {
      nodes {
        id: originalId
        title
      }
    }
    allPortalWine {
      nodes {
        ...AllPortalWine
      }
    }
    allPortalGrape {
      nodes {
        ...AllPortalGrape
      }
    }
    allPortalTag {
      nodes {
        ...AllPortalTag
      }
    }
    allPortalPairing {
      nodes {
        ...AllPortalPairing
      }
    }
    datoCmsCellar {
      stickerText
    }
  }
`
