import React from "react"
import Container from "../container/container"
import Navigator from "../global/navigator"
import style from "./grape-list.mod.scss"

const GrapeList = ({ items }) => {
  return (
      <div className={style.grapes}>
        <Container gutters width={"medium"} pt={1} pb={2}>
          <div className={style.grapes__grid}>
            {items.map((item) => (
              <Navigator recordId={item.id}><p>{item.title}</p></Navigator>
            ))}
          </div>
        </Container>
      </div>
  )
}

export default GrapeList
